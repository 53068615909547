'use client';

import React from 'react';
import Image from 'next/image';
import { FooterPrivacyPolicy } from './footer-privacy-policy';
import tiktokshop from '../../images/tiktok-shop-black.399fd9b2.8_JCpYmO.svg';
import metaTechProvider from '../../images/meta-tech-provider.d1716c83.CDbHHR6z.svg';
import { usePathname } from 'next/navigation';

const Footer: React.FC = () => {
  const pathname = usePathname();
  const [isRegPage, setIsRegPage] = React.useState<boolean>(true);

  const startsWithReg = pathname.startsWith('/register');

  React.useEffect(() => {
    setIsRegPage(startsWithReg);
  }, [startsWithReg]);

  return (
    <footer className="footer-section py-4 bg-neutral-100 border-t border-gray-300">
      <div className="container">
        <div className="flex flex-col md:flex-row items-center justify-between text-center md:text-left">
          {isRegPage ? (
            <div className="flex items-center justify-center md:justify-start space-x-4" />
          ) : (
            <>
              <div className="flex items-center justify-center md:justify-start space-x-4">
                <Image
                  width="110"
                  height="36"
                  src={metaTechProvider}
                  alt="Meta Tech Provider"
                  className="meta-tech-icon"
                />
                <Image
                  width="110"
                  height="36"
                  src={tiktokshop}
                  className="tiktok-icon"
                  alt="TikTok Shop Partner"
                />
              </div>
              <div className="footer-privacy-policy-divider" />
            </>
          )}

          <div className="mt-4 flex flex-col md:flex-row items-center md:items-end text-gray-500 text-sm footer-privacy-policy">
            <span>© 2025 ViralMango</span>
            <span className="hidden md:inline mx-2">|</span>{' '}
            <FooterPrivacyPolicy />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
